
import RecordPage from './RecordPage'
import EditorText from '../components/EditorText';
import EditorBool from '../components/EditorBool';
import EditorUser from '../components/EditorUser';
import EditorObject from '../components/EditorObject';

const form = ({record, onChangeFunc}) => {

  return (
    <div>
      <EditorText field={"id"} label={"Id"} readonly={true} 
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorObject field={"objectId"} label={"Object"} 
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorUser field={"principalId"} label={"Principal"} 
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorBool field={"read"} label={"Read"} 
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorBool field={"write"} label={"Write"} 
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorText field={"updated"} label={"Updated"} readonly={true}
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorText field={"created"} label={"Created"} readonly={true}
                  record={record} onChangeFunc={onChangeFunc}/>
    </div>
  );
};

const page = () => <RecordPage entityName="acls" FormType={form} />;

export default page;