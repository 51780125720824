import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import useEntityList from '../hooks/entityList';
import { useState } from 'react';
import { Alert } from '@mui/material';

function processError(error) {
  return error?.message ?? error;
}

const EntityGrid = ({entityName, columns}) => {
  const navigate = useNavigate();
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ sort, setSort ] = useState([]);
  const [ filter, setFilter ] = useState([]);
  const { rows, loaded, count, error } = useEntityList(entityName, page, pageSize, filter, sort);

  const handleRowClick = (x) => navigate(`/${entityName}/${x.row.id}`);
  const pageChanged = (page) => setPage(page);
  const pageSizeChanged = (size) => setPageSize(size);
  const isDynamicColumn = typeof(columns) === "function";
  const columnsList = isDynamicColumn ? columns(rows) : columns;

  const handleSortModelChange = (sortModel) => {
    setSort(sortModel);
  }

  const handleFilterModelChange = (filterModel) => {
    setFilter(filterModel);
  }

  for(let i = 0; i < columnsList.length; i++) {
    columnsList[i].headerAlign = 'center';
    columnsList[i].align = 'center';
  }

  return (
    <DataGrid
      loading={!loaded}
      rows={rows || []}
      editMode="row"
      columns={columnsList || []}
      sortingMode={"server"}
      onSortModelChange={handleSortModelChange}
      filterMode={"server"}
      onFilterModelChange={handleFilterModelChange}
      autoPageSize
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
      paginationMode='server'
      pagination
      rowCount={count}
      isCellEditable={() => false}
      onRowDoubleClick={handleRowClick}
      onPageChange={pageChanged}
      onPageSizeChange={pageSizeChanged}
      error={error}
      components={{
        ErrorOverlay: ({error}) => <Alert severity="error">{processError(error)}</Alert>
      }}
    />
  )
}

export default EntityGrid;