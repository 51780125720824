import { useEffect, useState } from "react";
import useAuth from "./auth";

const useRecordApi = (entityName, id) => {
  const [ record, setRecord ] = useState({});
  const [ loaded, setLoaded ] = useState(false);
  const [ error, setError ] = useState();
  const { token } = useAuth();

  useEffect(() => {
    const validId = !!id && id !== "new";
    async function loadRecord() {
      const uri = `/api/${encodeURIComponent(entityName)}/${encodeURIComponent(id)}`;
      try {
        setError();
        const result = await fetch(uri, {
          headers: {
            'Authorization': token
          }
        });
        if (result.ok) {
          const data = await result.json();
          setRecord(data);
        }
        else {
          const data = await result.text();
          setError(data);
          setRecord({});
        }
      } 
      catch(e) {
        setError(e.message);
        setRecord({});
      }
      finally {
        setLoaded(true);
      }
    }

    if (validId) {
      loadRecord();
    } else {
      setLoaded(true);
    }

  }, [entityName, id, token]);

  const save = async () => {
    const validId = !!id && id !== "new";
    setLoaded(false);
    const uri = validId ? `/api/${encodeURIComponent(entityName)}/${encodeURIComponent(id)}` :
                          `/api/${encodeURIComponent(entityName)}`;
    try {
      setError();
      const result = await fetch(uri, {
        method: validId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify(record)
      });
      const data = await result.text();
      if (result.ok) {
        return data;
      }
      else {
        setError(data);
        setRecord({});
      }
    }
    catch(e) {
      setError(e.message);
      setRecord({});
    }
    finally {
      setLoaded(true);
    }
  };

  return { record, error, setRecord, save, loaded };
}

export default useRecordApi;