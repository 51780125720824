import { AppBar, Toolbar, Button, Typography } from '@mui/material';
import EntityGrid from "../components/EntityGrid";
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';

const EntityPage = ({entityName, columns}) => {
  const navigate = useNavigate();
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
      <AppBar position="static">
        <Toolbar disableGutters>
          <Button sx={{ color: '#fff' }} onClick={x => navigate("/")}>
            <HomeIcon />
          </Button>
          <Button sx={{ color: '#fff' }} onClick={x => navigate("/events")}>
            <Typography variant="h6" component="div">Events</Typography>
          </Button>
          <Button sx={{ color: '#fff' }} onClick={x => navigate("/users")}>
            <Typography variant="h6" component="div">Users</Typography>
          </Button>
          <Button sx={{ color: '#fff' }} onClick={x => navigate("/acls")}>
            <Typography variant="h6" component="div">Permissions</Typography>
          </Button>
          <Button sx={{ color: '#fff' }} onClick={x => navigate("/logs")}>
            <Typography variant="h6" component="div">Logs</Typography>
          </Button>
          <Button sx={{ color: '#fff' }} onClick={x => navigate(`/${entityName}/new`)}>
            <AddIcon/>
          </Button>
        </Toolbar>
      </AppBar>
      <main style={{ flexGrow: 1 }}>
        <EntityGrid entityName={entityName} columns={columns} />
      </main>
    </div>
  );
}

export default EntityPage;