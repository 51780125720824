import EntityPage from "./EntityPage";
import DateTimeCell from "../components/columns/DateTimeCell"
import GuidCell from "../components/columns/GuidCell";

const columns = [
  { field: "id",      headerName: "Id",       editable: false, flex: 150, renderCell: ({value}) => <GuidCell value={value} /> },
  { field: "name",    headerName: "Name",     editable: false, flex: 150 },
  { field: "email",   headerName: "Email",    editable: false, flex: 150 },
  { field: "created", headerName: "Created",  editable: false, flex: 150, renderCell: ({value}) => <DateTimeCell value={value} /> },
  { field: "updated", headerName: "Updated",  editable: false, flex: 150, renderCell: ({value}) => <DateTimeCell value={value} /> },
];

const UsersList = () => {
  return (
    <EntityPage entityName={'users'} columns={columns} />
  );
}

export default UsersList;