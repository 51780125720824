import { TextField } from '@mui/material';

const EditorText = ({label, field, record, onChangeFunc, readonly}) => {
  const onChange = onChangeFunc(field);
  return <TextField margin="dense"
                    fullWidth 
                    variant="standard"
                    disabled={readonly}
                    label={label}
                    value={record[field] ?? ""} 
                    onChange={x => onChange(x.target.value)}/>;
}
  
  export default EditorText;