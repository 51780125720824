import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMemo } from 'react';

dayjs.extend(relativeTime);

const DateTimeCell = ({value}) => {
  const formattedValue = useMemo(() => dayjs(value).fromNow(), [value]);
  return <span title={value}>{formattedValue}</span>
}

export default DateTimeCell;