import { useEffect, useMemo, useState } from "react";
import DateTimeCell from "./DateTimeCell";
import GuidCell from "./GuidCell";
import ImageTooltip from "./ImageTooltip";

const formats = {
  unknown: 0,
  image: 1,
  dateTime: 2,
  guid: 3,
};

const isoDateRegex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d{3})?([+-]\d{2}:\d{2})?/;
const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const AutoCell = ({value}) => {
  const [format, setFormat] = useState(formats.unknown);

  useEffect(() => {
    setTimeout(() => {
      const isString = typeof(value) === "string";
      if (isString) {
        if (value.startsWith("data:image")) {
          setFormat(formats.image);
        } else if (isoDateRegex.test(value)) {
          setFormat(formats.dateTime);
        } else if (guidRegex.test(value)) {
          setFormat(formats.guid);
        }
      }
    }, 0);
  }, [value]);

  return useMemo(() => {
    switch(format) {
      case formats.dateTime:
        return <DateTimeCell value={value}/>
      case formats.image:
        return (
          <ImageTooltip value={value}>
            <span>Image</span>
          </ImageTooltip>
        );
      case formats.guid:
        return <GuidCell value={value}/>
      case formats.unknown:
      default:
        return <span title={value}>{value}</span>;
    }
  }, [value, format]);
}

export default AutoCell;