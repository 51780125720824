import { Box, Button, Stack } from "@mui/material"
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import useAuth from "../hooks/auth";
import LoginButton from '../components/LoginButton';

const HomePage = () => {
  const navigate = useNavigate();
  const { setBearer, isAuthenticated } = useAuth();

  const login = (token) => {
    setBearer(token);
    navigate("/");
  }

  return (
  <Box element="main">
    <Stack direction="row" spacing={2}>
      { isAuthenticated ? (
        <>
          <Button onClick={x => navigate("/events")}>
            <DynamicFormIcon/>
          </Button>
          <Button onClick={x => navigate("/users")}>
            <PersonIcon/>
          </Button>
          <Button onClick={x => navigate("/acls")}>
            <AdminPanelSettingsIcon/>
          </Button>
        </>
      ) : null }
      <LoginButton onLogin={login}/>
    </Stack>
  </Box>);
}

export default HomePage;