import { useCallback, useEffect, useRef } from "react";

const buttonStyle = {
  context: "signin",
  theme: "filled_blue",
  size: "medium",
  type: "standard",
  shape: "rectangular"
};

const LoginButton = ({onLogin}) => {
  const loginButtonRef = useRef(null);
  const client_id = "158416695727-23vkg8h5poktsenda2e3frruaf1aj8d2.apps.googleusercontent.com";

  const callback = useCallback((x) => {
    onLogin(x.credential);
  }, [onLogin]);

  useEffect(() => {
    if (loginButtonRef.current) {
      window.google.accounts.id.initialize({ client_id, callback, auto_select: true });
      window.google.accounts.id.renderButton(loginButtonRef.current, buttonStyle);
    }
  }, [loginButtonRef, callback]);

  return <div ref={loginButtonRef}></div>
}

export default LoginButton;