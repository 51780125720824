import EditorText from '../components/EditorText';
import RecordPage from './RecordPage'

const form = ({record, onChangeFunc}) => {

  return (
    <div>
      <EditorText field={"id"} label={"Id"} readonly={true}
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorText field={"name"} label={"Name"}
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorText field={"email"} label={"Email"}
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorText field={"updated"} label={"Updated"} readonly={true}
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorText field={"created"} label={"Created"} readonly={true}
                  record={record} onChangeFunc={onChangeFunc}/>
    </div>
  );
};

const page = () => <RecordPage entityName="users" FormType={form} />;

export default page;