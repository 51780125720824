import EditorBool from '../components/EditorBool';
import EditorText from '../components/EditorText';
import RecordPage from './RecordPage'

const Form = ({record, onChangeFunc}) => {

  return (
    <div>
      <EditorText field={"name"} label={"Name"}
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorText field={"email"} label={"Email"}
                  record={record} onChangeFunc={onChangeFunc}/>
      <EditorBool field={"demoData"} label={"Insert demo data"}
                  record={record} onChangeFunc={onChangeFunc}/>
    </div>
  );
};

const page = () => <RecordPage entityName="setup" FormType={Form} />;

export default page;