import BoolCell from "../components/columns/BoolCell";
import DateTimeCell from "../components/columns/DateTimeCell";
import EntityCell from "../components/columns/EntityCell";
import GuidCell from "../components/columns/GuidCell";
import PrincipalCell from "../components/columns/PrincipalCell";
import EntityPage from "./EntityPage";

const columns = [
  {
    field: "id",
    headerName: "Id",
    editable: false,
    flex: 15,
    renderCell: ({value}) => <GuidCell value={value} />
  },
  {
    field: "objectId",
    headerName: "Object",
    editable: false,
    flex: 30,
    renderCell: ({value}) => <EntityCell value={value}/>
  },
  {
    field: "principalId",
    headerName: "Principal",
    editable: false,
    flex: 30,
    renderCell: ({value}) => <PrincipalCell value={value} />
  },
  {
    field: "read",
    headerName: "Read",
    editable: false,
    flex: 15,
    renderCell: ({value}) => <BoolCell value={value} />
  },
  {
    field: "write",
    headerName: "Write",
    editable: false,
    flex: 15,
    renderCell: ({value}) => <BoolCell value={value} />
  },
  {
    field: "created",
    headerName: "Created",
    editable: false,
    flex: 15,
    renderCell: ({value}) => <DateTimeCell value={value} />
  },
  {
    field: "updated",
    headerName: "Updated",
    editable: false,
    flex: 15,
    renderCell: ({value}) => <DateTimeCell value={value} />
  },
];

const AclsList = () => {
  return (
    <EntityPage entityName={'acls'} columns={columns} />
  );
}

export default AclsList;