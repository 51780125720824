import useRecordApi from "../hooks/recordApi";
import { AppBar, Toolbar, Button, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const RecordPage = ({ entityName, FormType }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { record, setRecord, save, loaded, error } = useRecordApi(entityName, id);

  const onChangeInternal = (field) => (value) => setRecord({
    ...record,
    [field]: value
  });

  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
      <AppBar position="static">
        <Toolbar disableGutters>
          <Button sx={{ color: '#fff' }} onClick={x => navigate(`/${entityName}`)}>
            <ChevronLeftIcon/>
            <Typography variant="h6" component="div">{entityName}</Typography>
          </Button>
          <Button sx={{ color: '#fff' }} onClick={save}>
            <Typography variant="h6" component="div">Save</Typography>
          </Button>
        </Toolbar>
      </AppBar>
      <main style={{ flexGrow: 1, padding: 8 }}>
        {loaded ?
          <FormType entityName={entityName} id={id} record={record} onChangeFunc={onChangeInternal} onChange={setRecord}/>
         : <CircularProgress />}
      </main>
      <Snackbar open={error}>
        <Alert severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RecordPage;