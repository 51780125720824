import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Users from "./pages/UsersList";
import AclsList from "./pages/AclsList";
import EventsList from "./pages/EventsList";
import HomePage from "./pages/HomePage";
import UserPage from "./pages/UserPage";
import AclPage from "./pages/AclPage";
import LoginPage from "./pages/LoginPage";
import EventPage from "./pages/EventPage";
import SetupPage from "./pages/SetupPage";
import { AuthProvider } from "./hooks/auth";
import LogsList from "./pages/LogsList";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage/>}/>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/events" element={<EventsList/>}/>
          <Route path="/events/:id" element={<EventPage/>}/>
          <Route path="/users" element={<Users/>}/>
          <Route path="/users/:id" element={<UserPage/>}/>
          <Route path="/acls" element={<AclsList/>}/>
          <Route path="/acls/:id" element={<AclPage/>}/>
          <Route path="/logs" element={<LogsList/>}/>
          <Route path="/setup" element={<SetupPage/>}/>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
