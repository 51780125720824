import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/auth";

const LoginPage = () => {
  const { setToken } = useAuth();
  const [ data, setData ] = useState("");
  const navigate = useNavigate();

  const onLogin = () => {
    setToken(data);
    navigate("/");
  }

  return (
    <Stack direction={"row"}>
      <TextField margin="dense" variant="standard" label="Token"
                onChange={x => setData(x.target.value)} />
      <Button onClick={onLogin}>Login</Button>
    </Stack>
  )
}

export default LoginPage;