import { Button, InputAdornment, Menu, MenuItem, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import { EntityNames } from './columns/EntityCell';

const entityNames = Object.keys(EntityNames).map(x => ({ key: x, title: EntityNames[x] }));

const EntityQuickEnter = ({onChange, value}) => {
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);
  const title = EntityNames[value] ?? "Entities";

  return <>
    <Button ref={anchorEl} onClick={() => setOpen(true)}>{title}</Button>
    <Menu anchorEl={anchorEl.current} open={open} onClose={() => setOpen(false)}>
      {entityNames.map(x => <MenuItem
                              selected={x.value === value}
                              onClick={() => onChange(x.key)}
                              value={x.key}>
                              {x.title}
                            </MenuItem>)}
    </Menu>
  </>
}

const EditorObject = ({label, field, record, onChangeFunc, readonly}) => {
  const onChange = onChangeFunc(field);
  const value = record[field] ?? "";

  return (<>
    <TextField margin="dense"
              fullWidth
              variant="standard"
              disabled={readonly}
              label={label}
              value={value}
              onChange={x => onChange(x.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <EntityQuickEnter onChange={onChange} value={value} />
                </InputAdornment>
              }}/>
    
  </>);
}
  
export default EditorObject;