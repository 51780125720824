import { useState } from "react";
import EntityPage from "./EntityPage";
import DateTimeCell from "../components/columns/DateTimeCell"
import AutoCell from "../components/columns/AutoCell";
import GuidCell from "../components/columns/GuidCell";

const defaultColumns = [
  { field: "id",      headerName: "Id",       editable: false, flex: 15, renderCell: ({value}) => <GuidCell value={value} /> },
  { field: "created", headerName: "Created",  editable: false, flex: 15, renderCell: ({value}) => <DateTimeCell value={value} /> },
  { field: "updated", headerName: "Updated",  editable: false, flex: 15, renderCell: ({value}) => <DateTimeCell value={value} /> },
];

const customColumn = (field, headerName) => ({
  field,
  headerName,
  editable: false,
  flex: 15,
  renderCell: ({value}) => <AutoCell value={value} />
});

const EventsList = () => {
  const [columns, setColumns] = useState([...defaultColumns]);
  const [knownColumns] = useState(new Set(defaultColumns.map(x => x.field)));

  const customColumns = (rows) => {
    if (rows && rows.length > 0) {
      const initialLength = columns.length;

      for(let i = 0; i < rows.length; i++) {
        for(const field in rows[i]) {
          if (!knownColumns.has(field)) {
            knownColumns.add(field);
            columns.push(customColumn(field, field));
          }
        }
      }

      if (columns.length !== initialLength) {
        const updateColumns = [...columns];
        setColumns(updateColumns);
        return updateColumns;
      }
    }

    return columns;
  }

  return (
    <EntityPage entityName={'events'} columns={customColumns} />
  );
}

export default EventsList;