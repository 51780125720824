import { MenuItem, TextField } from '@mui/material';
import useEntityList from '../hooks/entityList';

const EditorUser = ({label, field, record, onChangeFunc, readonly}) => {
  const { rows, loaded } = useEntityList("users", 0, 100);
  
  const onChange = onChangeFunc(field);
  return <TextField margin="dense"
                    fullWidth select
                    variant="standard"
                    disabled={readonly || !loaded}
                    label={label}
                    value={record[field] ?? ""} 
                    onChange={x => onChange(x.target.value)}>
            <MenuItem value="">--</MenuItem>
            {rows?.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
          </TextField>
  }
  
  export default EditorUser;