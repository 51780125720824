import { createContext, useContext } from "react";

const initialAuthData = {
  token: ""
}

const AuthContext = createContext();

const useAuth = () => {
  const auth = useContext(AuthContext);
  const setToken = (value) => auth.token = value;
  const setBearer = (value) => setToken(`Bearer ${value}`);
  const isAuthenticated = !!(auth && auth.token && auth.token.length > 0);
  return { token: auth.token, setToken, setBearer, isAuthenticated };
}

export const AuthProvider = ({children}) => <AuthContext.Provider value={initialAuthData}>{children}</AuthContext.Provider>
export default useAuth;