import { useEffect, useState } from "react";
import useAuth from "../../hooks/auth";

const cachedEntity = {}

const PrincipalCell = ({value}) => {
  const [displayValue, setDisplayValue] = useState(value);
  const { token } = useAuth();

  useEffect(()=>{
    const cached = cachedEntity[value];
    if (cached) {
      setDisplayValue(cached);
    } else {
      (async () => {
        const uri = `/api/users/${value}`
        const result = await fetch(uri, {
          headers: {
            'Authorization': token
          }
        });
        if (result.ok) {
          const data = await result.json();
          if (data?.name) {
            setDisplayValue(data.name)
            cachedEntity[value] = data.name;
          }
        }
      })();
    }
  }, [value, token]);

  return <span title={value}>{displayValue}</span>
}
  
export default PrincipalCell;