import { Box, Button, TextField } from '@mui/material';
import { useState } from 'react';
import RecordPage from './RecordPage'

const Form = ({record, onChange}) => {

  const [value, setValue] = useState(JSON.stringify(record, null, "  "));

  const updateJSON = (x) => {
    const json = JSON.parse(value);
    onChange(json);
  }

  return (
    <Box>
      <TextField
        value={value}
        multiline
        fullWidth
        onChange={x => setValue(x.target.value)}
      />
      <Button onClick={updateJSON}>Apply</Button>
    </Box>
  );
};

const page = () => <RecordPage entityName="events" FormType={Form} />;

export default page;