
export const EntityNames = {
  "00000000-0000-ee1f-0000-000000000885" : "ACL",
  "00000000-0000-ee1f-0000-000000000886" : "Event",
  "00000000-0000-ee1f-0000-000000000887" : "User",
  "00000000-0000-ee1f-0000-000000000888" : "Log",
}

const EntityCell = ({value}) => {
  const cached = EntityNames[value];
  return <span title={value}>{cached ?? value}</span>
}

export default EntityCell;