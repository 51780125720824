import EntityPage from "./EntityPage";
import DateTimeCell from "../components/columns/DateTimeCell"

const columns = [
  {
    field: "created",
    headerName: "Created",
    editable: false,
    flex: 15,
    renderCell: ({value}) => <DateTimeCell value={value} />
  },
  {
    field: "category",
    headerName: "Category",
    editable: false,
    flex: 15
  },
  {
    field: "message",
    headerName: "Message",
    editable: false,
    flex: 30
  },
  {
    field: "stackTrace",
    headerName: "StackTrace",
    editable: false,
    flex: 15
  }
];

const LogsList = () => {
  return (
    <EntityPage entityName={'logs'} columns={columns} />
  );
}

export default LogsList;