import { styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Box } from '@mui/system';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#AAAAAA',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 550,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const ImagePreview = ({value}) => {
  return (
    <Box sx={{ p: 2 }}>
      <div style={{backgroundColor: '#FFFFFF'}}>
        <img 
          maxWidth={500}
          maxHeight={500}
          src={value}
          alt="preview"
        />
      </div>
    </Box>
  );
}

const ImageTooltip = ({children, value}) => {
  return (
    <HtmlTooltip title={<ImagePreview value={value}/>}>
      {children}
    </HtmlTooltip>
  );
}

export default ImageTooltip;