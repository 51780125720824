import { useEffect, useState } from "react";
import useAuth from "./auth";

const operationMap = {
  'contains': 'eq',
  'equals': 'eq',
}

function buildSort(sortModel, sortType) {
  return sortModel.filter(x => x.sort === sortType).map(x => x.field);
}

function buildFilter(filterModel) {

  if (filterModel && filterModel.items && filterModel.items.length > 0) {
    const filters = filterModel.items.map(x => `${x.columnField} ${operationMap[x.operatorValue]} ${x.value}`);
    return filters.join(" and ");
  }

  return [];
}

function createQuery(queryObj) {
  const queryArray = []

  for(const i in queryObj) {
    const value = String(queryObj[i]);
    if (value && value.length > 0) {
      queryArray.push(`${i}=${encodeURIComponent(value)}`);
    }
  }

  return queryArray.join("&");
}

const useEntityList = (entityName, page, pageSize, filterModel, sortModel) => {
  const [rows, setRows] = useState();
  const [loaded, setLoaded] = useState(false);
  const [count, setCount] = useState(0);
  const [error, setError] = useState();
  const [countError, setCountError] = useState();
  const { token } = useAuth();

  useEffect(() => {
    console.log(`Counting ${entityName} rows`);
    async function loadEntityCount() {

      const queryString = createQuery({
        filter: buildFilter(filterModel),
      });

      const uri = `/api/${encodeURIComponent(entityName)}/count?${queryString}`;
      try {
        setCountError();
        const result = await fetch(uri, {
          headers: {
            'Authorization': token
          }
        });
        const data = await result.text();
        if (result.ok) {
          setCount(Number(data));
        }
        else {
          setCountError(data);
          setCount(Number(0));
        }
      }
      catch(e) {
        setCountError(e.message);
        setCount(Number(0));
      }
    }
    loadEntityCount();
  }, [entityName, token, filterModel]);

  useEffect(() => {
    console.log(`Loading ${entityName} list`);
    async function loadEntityList() {
      setLoaded(false);
      try {
        setError();

        const queryString = createQuery({
          skip: page * pageSize,
          limit: pageSize,
          filter: buildFilter(filterModel),
          orderAsc: buildSort(sortModel, 'asc'),
          orderDesc: buildSort(sortModel, 'desc')
        });

        const uri = `/api/${encodeURIComponent(entityName)}?${queryString}`;
        const result = await fetch(uri, {
          headers: {
            'Authorization': token
          }
        });
        if (result.ok) {
          const data = await result.json();
          setRows(data);
        }
        else {
          const data = await result.text();
          setError(data);
          setRows([]);
        }
      }
      catch(e) {
        setError(e.message);
        setRows([]);
      }
      finally {
        setLoaded(true);
      }
    }
    loadEntityList();
  }, [entityName, page, pageSize, filterModel, sortModel, token]);

  return { rows, error: error ?? countError, loaded, count };
}

export default useEntityList;